<template>
  <div>
    <validation-provider
      v-slot="{ errors }"
      vid="autocomplete"
      name="autocomplete"
    >
      <b-form-group
        text-bolder
        label="Auto Complete Address"
        class="label"
      >
        <b-form-input
          id="autocomplete"
          v-model="query"
          placeholder="Start typing your address"
          trim
          class="input"
          type="text"
          @blur="removeCSS"
          @focus="initAutocomplete"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </b-form-group>
    </validation-provider>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BFormInput,
    BFormGroup,
    ValidationProvider,
  },
  data() {
    return {
      query: '',
      autocomplete: null,
    }
  },
  created() {
    this.initAutocomplete()
    window.addEventListener('scroll', this.updateAutocompletePosition, true)
  },
  methods: {
    removeScrollListener() {
      const pacContainers = document.getElementsByClassName('pac-container')
      while (pacContainers.length > 0) {
        pacContainers[0].parentNode.removeChild(pacContainers[0])
      }
      window.removeEventListener('scroll', this.updateAutocompletePosition, true)
    },
    removeCSS() {
      const pacContainers = document.getElementsByClassName('pac-container')
      while (pacContainers.length > 0) {
        pacContainers[0].parentNode.removeChild(pacContainers[0])
      }
    },
    initAutocomplete() {
      if (typeof google !== 'undefined') {
        // eslint-disable-next-line no-undef
        this.autocomplete = new google.maps.places.Autocomplete(document.getElementById('autocomplete'), {
          fields: ['address_components', 'geometry', 'name'],
          types: ['geocode'],
        })
        this.autocomplete.addListener('place_changed', this.getAddressData)
      }
    },
    getAddressData() {
      const place = this.autocomplete.getPlace()
      this.$emit('address-selected', place)
    },
    updateAutocompletePosition() {
      const pacContainer = document.querySelector('.pac-container')
      if (pacContainer) {
        const inputRect = document.getElementById('autocomplete').getBoundingClientRect()
        const containerRect = document.getElementById('autocomplete').getBoundingClientRect()
        pacContainer.style.position = 'relative'
        pacContainer.style.top = `${inputRect.bottom}px`
        pacContainer.style.left = `${containerRect.left}px`
        pacContainer.style.width = `${containerRect.width}px`
      }
    },
  },
}
</script>

  <style lang="scss" scoped>

  </style>
